nav {
	border-bottom: 1px solid #ccc;
	display: flex;
	align-items: center;
	padding: 8px;
	height: 5vh
}

.title {
	font-size: 1.5em;
	text-decoration: none;
	color: black;
	flex: 1;
}

.title-link {
	text-decoration: none;
	color: black;
}

.language-select {
	display: flex;
}

.language-select .link {
	margin: 0 4px;
}