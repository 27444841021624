.graph-data {
	position: relative;
	margin: 1rem;
	padding: 1rem;
	font-family: 'Courier New', Courier, monospace;
	background-color: antiquewhite;
	border: 3px solid bisque;
	height: 500px;
	overflow-y: auto;
	overflow-x: auto;
}