@font-face {
	font-family: Futura;
	src: url(./fonts/futura.ttf);
}
@font-face {
	font-family: 'Futura Outline';
	src: url(./fonts/futura-outline.otf);
}
body {
	margin: 0;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	min-width: 700px;
}
.App {
	min-height: 100vh;
}
.brand {
	font-family: Futura;
}
button.link {
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 1em;
	text-align: left;
	color: blue;
	background: none;
	margin: 0;
	padding: 0;
	border: none;
	cursor: pointer;
}